const MAP_URL = "https://maps.google.com/?q=:lat,:lng";

export const getMapLocationUrl = (lat?: number, lng?: number): string => {
  if (!lat || !lng) return "";

  return MAP_URL.replace(":lat", lat.toString()).replace(
    ":lng",
    lng.toString()
  );
};
