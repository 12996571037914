import React from "react";
import getConfig from "next/config";
import { useRouter } from "next/router";
import { Query } from "@apollo/react-components";

import { ButtonAnchor, Flex, Box, MultiSelect } from "components";
import { VIEW_AS_QUERY_PARAM } from "../../../src/constants";
import { usePlatformAdminCheck } from "../../../src/hooks";
import * as queries from "../../../src/queries";

import {
  administratorsConnection,
  administratorsConnectionVariables
} from "../../../src/core-types";
import { getQueryString } from "../../../src/utils/getQueryString";

type Props = {
  currentAdminId: string;
  viewAsId: string | null | undefined;
};

const {
  publicRuntimeConfig: { BASE_URL }
} = getConfig();

const ViewAs = ({ currentAdminId }: Props) => {
  const router = useRouter();
  const isPlatformAdmin = usePlatformAdminCheck();
  const selectId = "view-as-select";
  const viewAsAdminId = getQueryString(router.query[VIEW_AS_QUERY_PARAM]);

  const selectComponent = (
    <Query<administratorsConnection, administratorsConnectionVariables>
      query={queries.administrators}
      variables={{ first: 500 }}
    >
      {({ data }) => {
        const administrators =
          data &&
          data.administratorsConnection &&
          data.administratorsConnection.edges;

        if (!administrators) {
          return null;
        }
        const items = administrators.map(admin => ({
          id: admin.node.id,
          name: admin.node.name || admin.node.id
        }));

        return (
          <MultiSelect
            id={selectId}
            placeholder="Click to open"
            label="View as"
            values={
              viewAsAdminId ? [{ id: viewAsAdminId, name: viewAsAdminId }] : []
            }
            items={items}
            onChange={([admin]) => {
              let viewAsAdminId;
              if (!admin) {
                return;
              }
              if (admin.id !== currentAdminId) {
                const _admin = administrators.find(
                  a => a && a.node && a.node.id === admin.id
                );
                if (_admin && _admin.node && _admin.node.roles) {
                  viewAsAdminId = _admin.node.id;
                }
              }

              let url = BASE_URL;

              if (viewAsAdminId) {
                url = `${url}/?${VIEW_AS_QUERY_PARAM}=${viewAsAdminId}`;
              }

              window.location.href = url;
            }}
          />
        );
      }}
    </Query>
  );

  if (viewAsAdminId) {
    return (
      <Flex mr={4} alignItems="center">
        <Box mr={2}>{selectComponent}</Box>
        <ButtonAnchor small href={BASE_URL} color="white">
          Clear
        </ButtonAnchor>
      </Flex>
    );
  }

  return isPlatformAdmin ? <Box mr={4}>{selectComponent}</Box> : null;
};

export default ViewAs;
