import React from "react";
import styled, { keyframes } from "styled-components";
import {
  color,
  fontSize,
  FontSizeProps,
  space,
  SpaceProps
} from "styled-system";
import {
  RemoveAlt as RemoveIcon,
  Warning as WarningIcon,
  InfoAlt as InfoIcon,
  CheckmarkAlt as CheckIcon
} from "@urbaninfrastructure/react-icons";

import { H2, Flex } from "components";
import {
  Notification as NotificationType,
  removeNotification
} from "../../../src/context/globalState";
import { useGlobalState } from "../../../src/hooks";

type DialogProps = { minimal?: boolean };

type CloseButtonProps = FontSizeProps & SpaceProps;

const defaultIconProps = { mr: 3, mt: [1, 1, 1, 2] };

const iconMap = {
  info: <InfoIcon color="neutral.4" {...defaultIconProps} />,
  success: <CheckIcon color="primary" {...defaultIconProps} />,
  warning: <WarningIcon color="state.error" {...defaultIconProps} />,
  error: <WarningIcon color="state.error" {...defaultIconProps} />
};

const fadeIn = keyframes`
	from {
		opacity: 0;
    transform: translate(0,-50px) scale(0.5);
	}
`;

const Dialog = styled.div<DialogProps>`
  ${color};
  padding: ${({ theme, minimal }) =>
    minimal ? `${theme.space[1]}px` : `${theme.space[3]}px`};
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  background: ${({ theme }) => theme.colors.white};
  position: fixed;
  left: ${({ theme }) => theme.space[3]}px;
  right: ${({ theme }) => theme.space[3]}px;
  top: ${({ theme }) => theme.space[3]}px;
  z-index: 201;
  overflow: auto;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.12);
  max-height: 100vh;
  border-radius: ${({ theme }) => theme.radii["lg"]};
  animation: ${fadeIn} 500ms cubic-bezier(0.165, 0.84, 0.44, 1) both;
  ${({ theme }) => theme.mediaQueries[1]} {
    transform-origin: right top;
    padding: ${({ theme, minimal }) =>
      minimal
        ? `${theme.space[2]}px ${theme.space[3]}px`
        : `${theme.space[4]}px ${theme.space[5]}px`};
    margin-left: 1em;
    left: auto;
    right: 1em;
    top: ${({ minimal }) => (minimal ? "70px" : "1em")};
    max-width: 650px;
    max-height: 90vh;
  }
`;

const CloseButton = styled.button<CloseButtonProps>`
  ${space};
  ${fontSize};
  line-height: 1;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  border-radius: 50%;
  ${({ theme }) => theme.mediaQueries[1]} {
    margin-right: -0.5rem;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const StatelessNotificationArea = ({
  notifications,
  removeNotification
}: {
  notifications: NotificationType[];
  removeNotification: (value: string) => void;
}) => {
  if (notifications.length === 0) {
    return null;
  }

  const color = "#222";
  const notification = notifications[notifications.length - 1];
  return notification.minimal ? (
    <Dialog minimal>
      <Flex justifyContent="space-between" alignItems="flex-start">
        {iconMap[notification.level]}
        <H2
          role="alert"
          typoStyle="xs"
          style={{ fontWeight: "normal" }}
          mt={0}
          mb={0}
        >
          {notification.message}
          {notification.body}
        </H2>
      </Flex>
    </Dialog>
  ) : (
    <Dialog>
      <Flex justifyContent="space-between" alignItems="flex-start">
        <Flex>
          {iconMap[notification.level]}
          <H2
            role="alert"
            typoStyle="sm"
            style={{ fontWeight: "normal" }}
            mt={[1, 1, 1, 0]}
            mb={0}
          >
            {notification.message}
          </H2>
        </Flex>
        <CloseButton
          onClick={() => removeNotification(notification.id)}
          aria-label="Remove"
          data-testid="NotificationArea__CloseButton"
          color={color}
          p={[2, 2, 2, 3]}
          fontSize={4}
        >
          <RemoveIcon size="18px" style={{ display: "block" }} />
        </CloseButton>
      </Flex>
      {notification.body}
    </Dialog>
  );
};

export default function Notifications() {
  const [{ notifications }, dispatch] = useGlobalState();
  return (
    <StatelessNotificationArea
      notifications={notifications}
      removeNotification={id => dispatch(removeNotification(id))}
    />
  );
}
