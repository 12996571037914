import React from "react";
import Link from "next/link";
import Downshift from "downshift";
import styled from "styled-components";
import {
  Arrow,
  Button,
  Dropdown,
  DropdownItem,
  Flex,
  Text,
  VehicleIcon
} from "components";

import {
  globalData as globalDataQuery,
  globalData_systems
} from "../../../src/core-types";
import { useRouter } from "next/router";

const ArrowDown = () => <Arrow direction="down" size="12px" ml={2} />;

const SystemTrigger = styled(Button)`
  display: inline-block;
  text-decoration: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primaryContrast};
  border-color: transparent;
  border-radius: ${({ theme }) => theme.radii["md"]};
  padding: ${({ theme }) => theme.space[1]}px ${({ theme }) => theme.space[2]}px;
  &:hover,
  &:focus {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.2);
    color: ${({ theme }) => theme.colors.primaryContrast};
  }
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.neutral[3]};
  }
`;

const SystemTriggerForMainMenu = styled(SystemTrigger)`
  color: ${({ theme }) => theme.colors.text};
  width: 100%;
  background: ${({ theme }) => theme.colors.neutral[2]} !important;
`;

function SystemPicker(props: {
  id: string;
  administrator: globalDataQuery["administrator"];
  systems: globalDataQuery["systems"];
  systemId: string;
  showInMainMenu?: boolean;
  isOpen: boolean;
  onClick: () => void;
}) {
  const { id, isOpen, systemId, showInMainMenu, onClick, systems } = props;
  const router = useRouter();
  if (!systems || !router.pathname.startsWith("/[systemId]")) {
    return null;
  }

  const system = systems.find(system => {
    return system && system.id == systemId;
  });
  const systemName = system && system.name;

  // get first path of url, so when changing system we're still within the same category (eg, when on /currentSystemId/vehicles/1, it will change to /newSystemId/vehicles)
  const [, rootName, moduleName] = router.pathname.split("/");
  const pathname = `/${rootName}${moduleName ? `/${moduleName}` : ""}`;
  return (
    <>
      <Downshift<globalData_systems>
        id={id}
        isOpen={isOpen}
        onChange={item => {
          if (item) {
            router.push({
              pathname,
              query: { systemId: item.id }
            });
          }
        }}
        itemToString={item => (item && item.name) || ""}
      >
        {({
          getItemProps,
          getMenuProps,
          isOpen,
          highlightedIndex,
          getToggleButtonProps
        }) => {
          const systemTriggerProps = {
            title: "Select system",
            ...props,
            ...getToggleButtonProps({ onClick })
          };
          return (
            <div>
              <Flex position="relative" alignItems="center">
                {showInMainMenu ? (
                  <SystemTriggerForMainMenu {...systemTriggerProps}>
                    <Flex
                      alignItems="center"
                      color="text"
                      justifyContent="space-between"
                    >
                      {systemName}
                      <ArrowDown />
                    </Flex>
                  </SystemTriggerForMainMenu>
                ) : (
                  <SystemTrigger {...systemTriggerProps}>
                    <Flex alignItems="center">
                      {systemName}
                      <ArrowDown />
                    </Flex>
                  </SystemTrigger>
                )}
                <div {...getMenuProps()}>
                  <Dropdown
                    isOpen={isOpen}
                    mt={2}
                    width="auto"
                    data-testid="SystemPicker__Dropdown"
                  >
                    {systems.map((system, index) => {
                      if (!system) {
                        return null;
                      }
                      const selected = system.id === systemId;

                      return (
                        <Link
                          key={system.id}
                          href={{ pathname, query: { systemId: system.id } }}
                          passHref
                        >
                          <DropdownItem
                            {...getItemProps({
                              key: system.id,
                              item: system,
                              index
                            })}
                            selected={selected}
                            bg={highlightedIndex === index ? "neutral.1" : null}
                          >
                            <Flex alignItems="center">
                              <VehicleIcon mr={1} size={16} system={system} />
                              <Text whiteSpace="pre">{system.name}</Text>
                            </Flex>
                          </DropdownItem>
                        </Link>
                      );
                    })}
                  </Dropdown>
                </div>
              </Flex>
            </div>
          );
        }}
      </Downshift>
    </>
  );
}

export default SystemPicker;
