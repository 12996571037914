import { Box, Button, H3, Text } from "components";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { RemoveAlt as CloseIcon } from "@urbaninfrastructure/react-icons";
import { useQuery } from "@apollo/react-hooks";
import { ACTIVE_SYSTEM_ALERTS } from "modules/Dashboard/graphql";
import {
  SystemAlertVisibility,
  dashboardActiveSystemAlerts,
  dashboardActiveSystemAlertsVariables,
  dashboardActiveSystemAlerts_activeSystemAlerts
} from "src/core-types";

interface Props {
  systemId: string;
  language: string;
}

interface AlertText {
  title: string;
  description: string;
}

const StyledWrapper = styled(Box)`
  display: block;
  position: relative;
  top: 0;
  color: ${({ theme }) => theme.colors.primaryContrast};
  background-color: ${({ theme }) => theme.colors.primary};
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.white};
  boreder-bottom: 1px solid ${({ theme }) => theme.colors.white};
  padding: 20px;
`;

const StyledBox = styled(Box)`
  &:last-child {
    margin-bottom: 0;
  }
`;

const KEY_CLOSED_SYSTEM_ALERTS = "closedSystemAlerts";

const SystemAlertPopup = (props: Props) => {
  const [isClosed, setIsClosed] = useState(true);
  const [currentAlerts, setCurrentAlerts] = useState<
    dashboardActiveSystemAlerts_activeSystemAlerts[]
  >([]);
  const [alertTexts, setAlertTexts] = useState<AlertText[]>([]);

  const { data, error } = useQuery<
    dashboardActiveSystemAlerts,
    dashboardActiveSystemAlertsVariables
  >(ACTIVE_SYSTEM_ALERTS, {
    variables: { systemId: props.systemId }
  });

  const findLocalizedText = (items, language) => {
    return (
      items.find(item => item.language === language) ||
      items.find(item => !!item.text)
    );
  };

  useEffect(() => {
    let areAlertsSet = false;
    const alerts: dashboardActiveSystemAlerts_activeSystemAlerts[] = [];
    const alertTextsArray: AlertText[] = [];
    const closedSystemAlerts = localStorage.getItem(KEY_CLOSED_SYSTEM_ALERTS);
    const closedSystemAlertsArray = closedSystemAlerts?.split(",");

    for (const alert of data?.activeSystemAlerts || []) {
      if (
        alert.visibility !== SystemAlertVisibility.internal ||
        closedSystemAlertsArray?.includes(alert.id)
      ) {
        continue;
      }
      if (!alert.title || !alert.description) {
        return;
      }
      alerts.push(alert);

      const title = findLocalizedText(alert.title, props.language);
      const description = findLocalizedText(alert.description, props.language);
      alertTextsArray.push({
        title: title?.text ?? "",
        description: description?.text ?? ""
      });

      areAlertsSet = true;
    }
    if (areAlertsSet) {
      setIsClosed(false);
      setCurrentAlerts(alerts);
      setAlertTexts(alertTextsArray);
    }
  }, [data?.activeSystemAlerts, props.language]);

  const handleClose = () => {
    setIsClosed(true);
    setCurrentAlerts([]);
    setAlertTexts([]);
    const ids = currentAlerts.map(alert => alert.id).join(",");
    if (currentAlerts) {
      const closedSystemAlerts = localStorage.getItem(KEY_CLOSED_SYSTEM_ALERTS);
      localStorage.setItem(
        KEY_CLOSED_SYSTEM_ALERTS,
        closedSystemAlerts ? closedSystemAlerts + "," + ids : ids
      );
    }
  };

  if (error || !data?.activeSystemAlerts || !currentAlerts) {
    return null;
  }

  return (
    <>
      {!isClosed && (
        <StyledWrapper>
          <Button
            small
            onClick={() => {
              handleClose();
            }}
            aria-label="Close"
            variant="reset"
            style={{ float: "right" }}
          >
            <CloseIcon
              size="16px"
              color="primaryContrast"
              style={{ position: "absolute", top: "20px", right: "20px" }}
            />
          </Button>
          <Box className="system-alert system-alert-warning" role="alert">
            {alertTexts.map((alertText, index) => (
              <StyledBox key={index} mb={4}>
                <H3>{alertText.title}</H3>
                <Text key={index}>{alertText.description}</Text>
              </StyledBox>
            ))}
          </Box>
        </StyledWrapper>
      )}
    </>
  );
};

export default SystemAlertPopup;
