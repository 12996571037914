import { defineMessages } from "react-intl";

const messages = defineMessages({
  vehicleNotFound: {
    id: "modules.layout.navbar.vehicleNotFound",
    defaultMessage: "Vehicle not found."
  },
  cannotActivateCamera: {
    id: "modules.layout.navbar.cannotActivateCamera",
    defaultMessage: "Unable to activate the camera."
  }
});

export default messages;
