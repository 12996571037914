import isBoolean from "lodash/isBoolean";
import isObject from "lodash/isObject";
import isString from "lodash/isString";

import { System, SystemSettingsInput, RebalancingPageTab } from "../core-types";

type SettingKey = keyof SystemSettingsInput;

export function getArraySetting<R>(
  key: SettingKey,
  system: System
): R | undefined {
  const { settings } = system;
  return isObject(settings) &&
    Array.isArray(settings[key]) &&
    settings[key].length
    ? settings[key]
    : undefined;
}

export function getBooleanSetting(key: SettingKey, system: System): boolean {
  const { settings } = system;
  return isObject(settings) && isBoolean(settings[key]) ? settings[key] : false;
}

export function getStringSetting(
  key: SettingKey,
  system: System
): string | undefined {
  const { settings } = system;
  return isObject(settings) && isString(settings[key])
    ? settings[key]
    : undefined;
}

export function getRebalancingPageTabs(system: System): RebalancingPageTab[] {
  const tabs = getArraySetting<RebalancingPageTab[]>(
    "rebalancingPageTabs",
    system
  );
  return tabs
    ? tabs
    : [
        RebalancingPageTab.availability,
        RebalancingPageTab.list,
        RebalancingPageTab.map,
        RebalancingPageTab.misplacedVehicles,
        RebalancingPageTab.vehicleDeploy
      ];
}
