import React from "react";
import { useIntl, FormattedMessage, MessageDescriptor } from "react-intl";

import useSystem from "../src/hooks/useSystem";
import { getVehicleNouns } from "../src/utils/translation";

type Props = MessageDescriptor & {
  values?: Record<string, any>;
};

export const TranslatedMessage = ({ values = {}, ...props }: Props) => {
  const system = useSystem();
  const intl = useIntl();
  const nouns = getVehicleNouns(system, intl);
  return <FormattedMessage values={{ ...values, ...nouns }} {...props} />;
};
