import React from "react";
import Head from "next/head";
import styled, { css, keyframes } from "styled-components";
import { ApolloError } from "apollo-client";
import { QueryResult } from "@apollo/react-common";

import {
  Flex,
  Box,
  Loading,
  Button,
  Text,
  GraphQLNetworkError
} from "components";
import {
  VERSION,
  GITSHA,
  NAV_BAR_ROUTES,
  SSO_ENDPOINT,
  BASE_URL,
  VIEW_AS_QUERY_PARAM
} from "../../src/constants";
import { UpdateAdministrator } from "../../src/mutations";
import { filterRoutesByRoles } from "../../src/utils/filterRoutesByRoles";

import NotificationArea from "./NotificationArea";
import NavBar from "./NavBar";
import MainMenu from "./MainMenu";
import Footer, { FeatureLinkWrapper, LocalTime, TimeZone } from "./Footer";
import PageHeader, { PageHeaderProps } from "./PageHeader";
import CenteredContainer from "./CenteredContainer";
import AdminInteractionSubscription from "./AdminInteractionSubscription";
import { getArraySetting } from "../../src/utils/systemSettings";

import {
  globalData as globalDataQuery,
  globalDataVariables,
  MainMenuItem
} from "../../src/core-types";
import { RouteModel } from "../../src/types";
import { useGlobalData } from "../../src/queries";
import { useRouter } from "next/router";
import FeedbackForm from "components/FeedbackForm";
import SystemAlertPopup from "components/SystemAlertPopup";

type Query = {
  data?: unknown;
  error?: ApolloError | null | undefined;
  loading?: boolean;
};

export type Props = {
  title: string;
  heading?: React.ReactNode;
  name?: string;
  /** @deprecated not needed anymore */
  globalData?: globalDataQuery & Partial<QueryResult<globalDataVariables>>;
  render?: () => React.ReactNode;
  children?: React.ReactNode;
  /** @deprecated not needed anymore */
  url?: {
    pathname: string;
    query: Record<string, any>;
  };
  fluid?: boolean;
  showFooter?: boolean;
  pageHeaderProps?: PageHeaderProps;
  graphqlQueries?: (Query | null | undefined)[];
  breadcrumbTitleOverride?: string;
  hidePageHeader?: boolean;
};

const NAV_BAR_HEIGHT = "3.5rem";
const EXPANDED_MENU_WIDTH = "14rem";
const COLLAPSED_MENU_WIDTH = "4rem";

const FrameLayoutWrapperCss = ({ theme, mainMenuIsOpen }) => css`
  flex-grow: 1;
  ${theme.mediaQueries[3]} {
    padding-left: ${COLLAPSED_MENU_WIDTH};
    transition: padding 250ms ease;
    ${mainMenuIsOpen &&
      css`
        padding-left: ${EXPANDED_MENU_WIDTH};
      `};
  }
`;

const menuAnimation = keyframes`
0%   { opacity: 0; transform: translate(-40px, 0); }
100% { opacity: 1; transform: translate(0, 0); }`;

const FrameLayoutMenuCss = ({
  theme,
  mainMenuIsOpen,
  mainMenuOnMobileIsOpen
}) => css`
  position: fixed;
  left: 0;
  top: ${NAV_BAR_HEIGHT};
  bottom: 0;
  width: ${EXPANDED_MENU_WIDTH};
  background: ${theme.colors.neutral[1]};
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.12);
  border-right: 1px solid ${theme.colors.borderGray};
  z-index: 5;
  display: none;
  ${mainMenuOnMobileIsOpen &&
    css`
      position: fixed;
      display: block;
      animation: ${menuAnimation} 400ms cubic-bezier(0.19, 1, 0.22, 1) both;
    `};

  ${theme.mediaQueries[3]} {
    display: block;
    box-shadow: none;
    width: ${COLLAPSED_MENU_WIDTH};
    ${mainMenuIsOpen &&
      css`
        width: ${EXPANDED_MENU_WIDTH};
      `};
  }
`;

const FrameLayoutFooterCss = ({ theme }) => css`
  border-top: 1px solid ${theme.colors.borderGray};
`;

const FrameLayoutCss = ({ theme, mainMenuOnMobileIsOpen }) => css`
  min-height: 100vh;
  ${theme.mediaQueries[3]} {
    position: relative;
  }
  ${mainMenuOnMobileIsOpen &&
    css`
      /* Prevent scrolling while main menu is open on mobile */
      @media screen and (max-width: ${theme.breakpoints[3]}) {
        height: 100vh;
        overflow: hidden;
      }
    `};
`;

const FrameLayoutBarCss = ({ theme }) => css`
  width: 100%;
  flex-grow: 0;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  ${theme.mediaQueries[3]} {
    position: fixed;
    z-index: 5;
  }
`;

const overlayAnimation = keyframes`
0%   { opacity: 0 }
100% { opacity: 1 }`;

const CloseMenuOverlayCss = ({ theme }) => css`
  @media screen and (max-width: ${theme.breakpoints[3]}) {
    position: fixed;
    left: 0;
    top: ${NAV_BAR_HEIGHT};
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.25);
    z-index: 5;
    animation: ${overlayAnimation} 400ms cubic-bezier(0.19, 1, 0.22, 1) both;
  }
`;

const FrameLayout = styled(Flex)`
  ${FrameLayoutCss};
`;
const FrameLayoutBar = styled(Box)`
  ${FrameLayoutBarCss};
`;
const FrameLayoutWrapper = styled(Flex)`
  ${FrameLayoutWrapperCss};
`;
const FrameLayoutMenu = styled(Box)`
  ${FrameLayoutMenuCss};
`;
const FrameLayoutFooter = styled(Box)`
  ${FrameLayoutFooterCss};
`;

const FrameLayoutCloseMenuOverlay = styled.div`
  ${CloseMenuOverlayCss};
`;

function reducer(state, action) {
  switch (action.type) {
    case "TOGGLE_PROFILE_DROPDOWN": {
      return {
        ...state,
        profileIsOpen: !state.profileIsOpen,
        systemPickerIsOpen: false
      };
    }
    case "TOGGLE_SYSTEM_PICKER_DROPDOWN": {
      return {
        ...state,
        systemPickerIsOpen: !state.systemPickerIsOpen,
        profileIsOpen: false
      };
    }
    case "TOGGLE_MAIN_MENU": {
      return {
        ...state,
        systemPickerIsOpen: false,
        profileIsOpen: false,
        mainMenuIsOpen: !state.mainMenuIsOpen
      };
    }
    case "TOGGLE_MAIN_MENU_ON_MODILE": {
      return {
        ...state,
        systemPickerIsOpen: false,
        profileIsOpen: false,
        mainMenuOnMobileIsOpen: !state.mainMenuOnMobileIsOpen
      };
    }
    case "CLOSE_DROPDOWNS": {
      return {
        ...state,
        systemPickerIsOpen: false,
        profileIsOpen: false
      };
    }
    default: {
      return state;
    }
  }
}

const Base = ({
  hideNavigation,
  showFooter,
  hidePageHeader,
  title,
  heading,
  name,
  render,
  fluid,
  graphqlQueries = [],
  pageHeaderProps,
  handleMainMenuIsOpenChange,
  breadcrumbTitleOverride,
  children
}: {
  hideNavigation?: boolean;
  hidePageHeader?: boolean;
  showFooter?: boolean;
  handleMainMenuIsOpenChange?: (value: boolean) => void;
} & Props) => {
  const router = useRouter();
  const globalData = useGlobalData();
  const { collapseMainMenu } = globalData.administrator.settings;
  const [
    {
      mainMenuIsOpen,
      profileIsOpen,
      systemPickerIsOpen,
      mainMenuOnMobileIsOpen
    },
    dispatch
  ] = React.useReducer(reducer, {
    profileIsOpen: false,
    systemPickerIsOpen: false,
    mainMenuIsOpen: collapseMainMenu === undefined ? true : !collapseMainMenu,
    mainMenuOnMobileIsOpen: false
  });

  // close all dropdowns on escape click
  React.useEffect(() => {
    function onKeyUp(event) {
      if (event.keyCode === 27) {
        dispatch({ type: "CLOSE_DROPDOWNS" });
      }
    }
    window.addEventListener("keyup", onKeyUp);
    return () => {
      window.removeEventListener("keyup", onKeyUp);
    };
  }, []);

  const viewAsQueryParam = router.query[VIEW_AS_QUERY_PARAM];

  React.useEffect(() => {
    if (
      !handleMainMenuIsOpenChange ||
      collapseMainMenu === !mainMenuIsOpen ||
      viewAsQueryParam
    ) {
      return;
    }
    handleMainMenuIsOpenChange(mainMenuIsOpen);
  }, [
    mainMenuIsOpen,
    collapseMainMenu,
    handleMainMenuIsOpenChange,
    viewAsQueryParam
  ]);

  function toggleMenu() {
    dispatch({ type: "TOGGLE_MAIN_MENU" });
  }

  function toggleMenuOnMobile() {
    dispatch({ type: "TOGGLE_MAIN_MENU_ON_MODILE" });
  }

  function toggleSystemPickerDropdown() {
    dispatch({ type: "TOGGLE_SYSTEM_PICKER_DROPDOWN" });
  }

  function toggleProfileDropdown() {
    dispatch({ type: "TOGGLE_PROFILE_DROPDOWN" });
  }

  const { administrator } = globalData;
  const { selectedSystem } = administrator;

  let routes: RouteModel[] = [];
  if (administrator && !hideNavigation) {
    const mainMenuItems = getArraySetting<MainMenuItem[]>(
      "mainMenuItems",
      selectedSystem
    );
    const navBarRoutes = mainMenuItems
      ? NAV_BAR_ROUTES.filter(({ key }) => mainMenuItems.includes(key))
      : NAV_BAR_ROUTES;

    routes = filterRoutesByRoles(
      selectedSystem.id,
      navBarRoutes,
      administrator.roles
    );
  }

  const graphqlLoading = graphqlQueries.some(query => query && query.loading);
  const graphqlErrors = graphqlQueries.reduce<ApolloError[]>((accum, query) => {
    if (query && query.error) {
      accum.push(query.error);
    }
    return accum;
  }, []);
  const haveGraphQLErrors = graphqlErrors.length > 0;

  const titleAndSystem = title
    ? `${title} - ${selectedSystem.name || selectedSystem.id}`
    : selectedSystem.name || selectedSystem.id;

  return (
    <div data-testid="Layout__Base">
      <Head>
        <title>{titleAndSystem}</title>
      </Head>

      <FrameLayout
        mainMenuOnMobileIsOpen={mainMenuOnMobileIsOpen}
        flexDirection="column"
        justifyContent="flex-start"
        pt={NAV_BAR_HEIGHT}
        bg="neutral.0"
      >
        {!hideNavigation && routes && (
          <FrameLayoutBar>
            <Box>
              <NavBar
                url={router}
                administrator={globalData.administrator}
                systems={globalData.systems}
                systemId={globalData.administrator.selectedSystem.id}
                routes={routes}
                toggleMenu={toggleMenu}
                profileIsOpen={profileIsOpen}
                systemPickerIsOpen={systemPickerIsOpen}
                toggleSystemPickerDropdown={toggleSystemPickerDropdown}
                toggleProfileDropdown={toggleProfileDropdown}
                toggleMenuOnMobile={toggleMenuOnMobile}
                menuIsOpen={mainMenuIsOpen}
                menuOnMobileIsOpen={mainMenuOnMobileIsOpen}
                breadcrumbTitleOverride={breadcrumbTitleOverride}
              />
            </Box>
          </FrameLayoutBar>
        )}
        <FrameLayoutWrapper
          flexDirection="column"
          mainMenuIsOpen={hideNavigation ? false : mainMenuIsOpen}
        >
          {!hideNavigation && routes && (
            <React.Fragment>
              {mainMenuOnMobileIsOpen && (
                <FrameLayoutCloseMenuOverlay onClick={toggleMenuOnMobile} />
              )}
              <FrameLayoutMenu
                mainMenuIsOpen={mainMenuIsOpen}
                mainMenuOnMobileIsOpen={mainMenuOnMobileIsOpen}
              >
                <MainMenu
                  pathName={router.pathname}
                  routes={routes}
                  administrator={globalData.administrator}
                  systems={globalData.systems}
                  systemId={globalData.administrator.selectedSystem.id}
                  profileIsOpen={profileIsOpen}
                  systemPickerIsOpen={systemPickerIsOpen}
                  toggleSystemPickerDropdown={toggleSystemPickerDropdown}
                  toggleProfileDropdown={toggleProfileDropdown}
                  menuIsOpen={mainMenuIsOpen}
                  mainMenuOnMobileIsOpen={mainMenuOnMobileIsOpen}
                />
              </FrameLayoutMenu>
            </React.Fragment>
          )}
          <NotificationArea />
          <SystemAlertPopup
            systemId={globalData.administrator.selectedSystem.id}
            language={
              globalData.administrator.selectedSystem.defaultLanguageCode
            }
          />
          {globalData.administrator && (
            <AdminInteractionSubscription
              administratorId={globalData.administrator.id}
            />
          )}
          <Flex flexDirection="column" flex={1} px={[4, 6]} py={[2, 3]}>
            {!hidePageHeader && (
              <PageHeader
                heading={heading || title}
                name={name}
                fluid={fluid}
                bleed
                {...(pageHeaderProps || {})}
              />
            )}
            {graphqlLoading && (
              <Loading large>Loading {title.toLowerCase()}</Loading>
            )}
            {haveGraphQLErrors && (
              <GraphQLNetworkError
                errors={graphqlErrors}
                message={`Could not load all ${title.toLowerCase()} properly`}
              />
            )}
            {!graphqlLoading && render ? render() : children}
          </Flex>
          {showFooter ? (
            <FrameLayoutFooter mt={6} px={{ xs: 4, xl: 6 }} py={4}>
              <Footer
                system={globalData.administrator.selectedSystem}
                administrator={globalData.administrator}
                asPath={router.pathname}
                version={VERSION}
                gitsha={GITSHA}
              />
            </FrameLayoutFooter>
          ) : (
            <Flex
              mt={6}
              px={{ xs: 4, xl: 6 }}
              py={4}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Box mr={2}>
                <TimeZone small />
              </Box>
              <Box mr={2}>
                <LocalTime small />
              </Box>
              {!selectedSystem.settings.disableAdminFeedbackForm && (
                <FeatureLinkWrapper>
                  <FeedbackForm version={VERSION} gitsha={GITSHA} />
                </FeatureLinkWrapper>
              )}
            </Flex>
          )}
        </FrameLayoutWrapper>
      </FrameLayout>
    </div>
  );
};

export function GlobalDataError({ error }: { error: ApolloError }) {
  return (
    <CenteredContainer column>
      <GraphQLNetworkError
        errors={[error]}
        message={`Could not fetch data. Try logging out and in again, or contact a system administrator.`}
      />
      {/* Redirect to core log out endpoint */}
      <Button
        as="a"
        href={`${SSO_ENDPOINT}/auth/logout?redirect=${BASE_URL}/logout`}
        variant="primary"
      >
        Log out
      </Button>
    </CenteredContainer>
  );
}

export function GlobalDataLoading({ children }: { children: React.ReactNode }) {
  return (
    <CenteredContainer>
      <Loading large>{children}</Loading>
    </CenteredContainer>
  );
}

GlobalDataLoading.defaultProps = {
  children: "Loading"
};

export default function Layout(props: Props) {
  const globalData = useGlobalData();

  const fullScreenProps = {
    showFooter: false,
    hideNavigation: true,
    hidePageHeader: true,
    ...props
  };
  // if system is not in systems, user doesn't have access
  if (!globalData.administrator.selectedSystem) {
    return (
      <Base
        {...fullScreenProps}
        render={() => (
          <CenteredContainer>
            <Text as="p">Uh oh, no system selected</Text>
          </CenteredContainer>
        )}
      />
    );
  }
  return (
    <UpdateAdministrator
      onCompleted={() => {
        document.body.removeAttribute("data-updating-main-menu-state");
      }}
    >
      {(updateAdministrator, { loading }) => {
        return (
          <Base
            {...props}
            handleMainMenuIsOpenChange={mainMenuIsOpen => {
              if (loading) {
                return;
              }
              // used for cypress tests
              document.body.setAttribute(
                "data-updating-main-menu-state",
                "true"
              );
              updateAdministrator({
                variables: {
                  id: globalData.administrator.id,
                  input: {
                    settings: {
                      collapseMainMenu: !mainMenuIsOpen
                    }
                  }
                }
              });
            }}
          />
        );
      }}
    </UpdateAdministrator>
  );
}
