import React from "react";
import { useSystem } from "src/hooks";
import { useGlobalData } from "src/queries";
import { ClickUpFeedbackLink } from "./ClickUpFeedbackLink";
import { SlackFeedbackForm } from "./SlackFeedbackForm";

const FeedbackForm = ({
  version,
  gitsha
}: {
  version: string;
  gitsha: string;
}) => {
  const system = useSystem();
  const { administrator } = useGlobalData();
  if (system.feedbackFormUrl) {
    return <ClickUpFeedbackLink feedbackFormUrl={system.feedbackFormUrl} />;
  }
  return (
    <SlackFeedbackForm
      administrator={administrator}
      version={version}
      gitsha={gitsha}
    />
  );
};

export default FeedbackForm;
