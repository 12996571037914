import React from "react";
import styled from "styled-components";
import {
  Menu as MenuIcon,
  RemoveAlt as CloseIcon
} from "@urbaninfrastructure/react-icons";

import SystemPicker from "./SystemPicker";
import Profile from "./Profile";
import {
  ActiveSystemAlerts,
  Box,
  Breadcrumbs,
  Button,
  ButtonAnchor,
  Flex,
  Hide,
  HorizontalOverflow,
  Text
} from "components";
import { VIEW_AS_QUERY_PARAM } from "../../../src/constants";
import { useAccessControl, useEventTracking } from "../../../src/hooks";
import { RouteModel } from "../../../src/types";
import { globalData as globalDataQuery } from "../../../src/core-types";
import { QrReaderModal } from "./QrReaderModal";
import { getBooleanSetting } from "src/utils/systemSettings";

type Props = {
  url: {
    pathname: string;
    query: Record<string, any>;
  };
  administrator: globalDataQuery["administrator"];
  systems: globalDataQuery["systems"];
  systemId: string;
  routes: RouteModel[];
  profileIsOpen: boolean;
  systemPickerIsOpen: boolean;
  menuIsOpen: boolean;
  menuOnMobileIsOpen: boolean;
  toggleSystemPickerDropdown: () => void;
  toggleProfileDropdown: () => void;
  toggleMenu: () => void;
  toggleMenuOnMobile: () => void;
  breadcrumbTitleOverride?: string;
};

const MenuButton = styled(Button)`
  background-color: transparent;
  border: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.primaryContrast};
  border-radius: ${({ theme }) => theme.radii["md"]};
  padding: ${({ theme }) => theme.space[1]}px ${({ theme }) => theme.space[2]}px;
  margin-left: -${({ theme }) => theme.space[2]}px;
  margin-right: ${({ theme }) => theme.space[2]}px;
  &:hover,
  &:focus {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.2);
  }
`;

const NavBar = ({
  url,
  administrator,
  systems,
  systemId,
  toggleMenu,
  profileIsOpen,
  menuIsOpen,
  menuOnMobileIsOpen,
  systemPickerIsOpen,
  toggleSystemPickerDropdown,
  toggleProfileDropdown,
  toggleMenuOnMobile,
  breadcrumbTitleOverride
}: Props) => {
  const canReadSystemAlert = useAccessControl("read", "systemAlert");
  const trackEvent = useEventTracking();
  const label = !menuIsOpen ? "Expand menu" : "Contract menu";
  const mobileLabel = !menuOnMobileIsOpen ? "Open menu" : "Hide menu";

  const { selectedSystem: system } = administrator;
  const showQrScanButton = getBooleanSetting("enableQrScanButton", system);

  return (
    <HorizontalOverflow collapsed fadeColor="primary">
      <Box bg="primary" color="primaryContrast" px={3} data-testid="header">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          style={{ minHeight: "3.5rem" }}
        >
          <Flex alignItems="center">
            <Text p={2} m={0}>
              <Flex alignItems="center">
                <Hide xs sm md lg>
                  <MenuButton
                    onClick={() => {
                      trackEvent({
                        action: "Toggled main menu",
                        category: "Main menu",
                        label: menuIsOpen ? "Closed" : "Opened"
                      });

                      return toggleMenu();
                    }}
                    aria-label={label}
                    title={label}
                    data-testid="NavBar__MenuButton"
                  >
                    <MenuIcon
                      color="primaryContrast"
                      size="24px"
                      style={{ verticalAlign: "middle" }}
                    />
                  </MenuButton>
                </Hide>
                <Hide xl>
                  <MenuButton
                    onClick={() => {
                      trackEvent({
                        action: "Toggled main menu",
                        category: "Main menu",
                        label: menuIsOpen ? "Closed" : "Opened"
                      });

                      return toggleMenuOnMobile();
                    }}
                    aria-label={mobileLabel}
                    title={mobileLabel}
                    data-testid="NavBar__MenuButton__Mobile"
                  >
                    {menuOnMobileIsOpen ? (
                      <CloseIcon
                        color="primaryContrast"
                        size="24px"
                        style={{ verticalAlign: "middle" }}
                      />
                    ) : (
                      <MenuIcon
                        color="primaryContrast"
                        size="24px"
                        style={{ verticalAlign: "middle" }}
                      />
                    )}{" "}
                  </MenuButton>
                </Hide>
                <Breadcrumbs
                  breadcrumbTitleOverride={breadcrumbTitleOverride}
                />
              </Flex>
            </Text>
          </Flex>

          {canReadSystemAlert && (
            <Hide xl lg md>
              <Flex alignItems="flex-end">
                {showQrScanButton && <QrReaderModal systemId={systemId} />}
                <ActiveSystemAlerts systemId={systemId} variant="icon-only" />
              </Flex>
            </Hide>
          )}

          <Hide xs sm>
            <Flex alignItems="center">
              <Flex alignItems="center">
                {administrator && url.query[VIEW_AS_QUERY_PARAM] && (
                  <Box mr={4}>
                    Currently viewing as{" "}
                    {administrator.name || administrator.id}{" "}
                    <ButtonAnchor href="/">Reset</ButtonAnchor>
                  </Box>
                )}

                {canReadSystemAlert && (
                  <Box mr={4}>
                    <ActiveSystemAlerts systemId={systemId} />
                  </Box>
                )}

                {showQrScanButton && <QrReaderModal systemId={systemId} />}
              </Flex>

              <Flex alignItems="center">
                {systems.length > 1 && (
                  <Box mr={4}>
                    <SystemPicker
                      id="NavBar__SystemPicker"
                      data-testid="NavBar__SystemPicker"
                      systems={systems}
                      administrator={administrator}
                      systemId={systemId}
                      isOpen={systemPickerIsOpen}
                      onClick={toggleSystemPickerDropdown}
                    />
                  </Box>
                )}
                <Profile
                  systemId={systemId}
                  administrator={administrator}
                  isOpen={profileIsOpen}
                  onClick={toggleProfileDropdown}
                />
              </Flex>
            </Flex>
          </Hide>
        </Flex>
      </Box>
    </HorizontalOverflow>
  );
};

export default NavBar;
