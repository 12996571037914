import React from "react";
import { Box, Flex } from "@urbaninfrastructure/react-ui-kit";
import { paletteLight, theme } from "@urbaninfrastructure/design-tokens";
import { Anchor } from "../Anchor";
import { StatusPageResponse } from "./types";

type Props = {
  statusPageResponse: StatusPageResponse;
  render?: (response: StatusPageResponse) => React.ReactNode;
};

const colorMap = {
  OPERATIONAL: paletteLight.green01,
  DEGRADED: "rgb(250,210,1)", // Yellow
  MAJOROUTAGE: theme.red01.hex,
  MINOROUTAGE: "rgb(237,118,14)" // Orange
};

const DOT_RADIUS = 16;

export const Status = ({ statusPageResponse, render }: Props) => {
  const {
    page: { url },
    // Api response is not guaranteed to have these fields so we provide defaults
    activeIncidents = [],
    activeMaintenances = []
  } = statusPageResponse;

  // We prioritize incidents over maintenances and main status
  const indicator =
    activeIncidents.length > 0 ? activeIncidents[0].impact : "OPERATIONAL";

  const description =
    activeIncidents.length > 0
      ? activeIncidents[0].name
      : activeMaintenances.length > 0
      ? activeMaintenances[0].name
      : "All systems operational";

  return (
    <Anchor href={url} display="block">
      <Flex alignItems="center" justifyContent="center">
        <Box
          as="span"
          display="inline-block"
          m={1}
          mr={3}
          width={DOT_RADIUS}
          height={DOT_RADIUS}
          bg={colorMap[indicator] || paletteLight.green01}
          borderRadius="50%"
        />
        {render ? render(statusPageResponse) : description}
      </Flex>
    </Anchor>
  );
};

export default Status;
