import React from "react";
import { Anchor } from "@urbaninfrastructure/react-ui-kit";
import { ChatAlt as ChatIcon } from "@urbaninfrastructure/react-icons";
import { useRouter } from "next/router";
import { BASE_URL } from "src/constants";
import { useGlobalData } from "src/queries";

export const ClickUpFeedbackLink = ({
  feedbackFormUrl
}: {
  feedbackFormUrl: string;
}) => {
  const { administrator } = useGlobalData();
  const router = useRouter();
  const url = new URL(feedbackFormUrl);
  url.searchParams.set("AdministratorID", administrator.id);
  url.searchParams.set("URL", `${BASE_URL}${router.asPath}`);
  if (administrator.email) {
    url.searchParams.set("AdministratorEmail", administrator.email);
  }
  if (administrator.name) {
    url.searchParams.set("AdministratorName", administrator.name);
  }
  if (administrator.selectedSystem.cityName) {
    url.searchParams.set("CityName", administrator.selectedSystem.cityName);
  }
  return (
    <Anchor href={url.toString()} target="blank">
      <ChatIcon />
      Feedback
    </Anchor>
  );
};
