import React from "react";
import gql from "graphql-tag";
import { defineMessages } from "react-intl";
import { Query } from "@apollo/react-components";
import { useSubscription } from "@apollo/react-hooks";

import {
  Grid,
  GridCell,
  Info,
  Button,
  SystemLink,
  BatteryLevel,
  VehicleDisplay,
  ButtonAnchor
} from "components";
import { ControllerLink } from "components/links";
import { TranslatedMessage } from "components/translations";
import { VehicleDisplayTitle } from "components/VehicleDisplay";

import {
  addNotificationWithTTL,
  clearNotifications
} from "../../src/context/globalState";
import { useGlobalState } from "../../src/hooks";
import {
  onAdminInteraction as onAdminInteractionSubscription,
  onAdminInteractionVariables,
  adminInteractionController as adminInteractionControllerQuery,
  adminInteractionControllerVariables,
  adminInteractionController_controller_pairedEntity_Vehicle
} from "../../src/core-types";

type Props = {
  administratorId: string;
};

const messages = defineMessages({
  vehicleTitle: {
    id: "modules.Layout.AdminInteractionSubscription.vehicleTitle",
    defaultMessage: "{vehicleNounUpperFirst}"
  },
  controllerTitle: {
    id: "modules.Layout.AdminInteractionSubscription.controllerTitle",
    defaultMessage: "Controller"
  },
  stateTitle: {
    id: "modules.Layout.AdminInteractionSubscription.stateTitle",
    defaultMessage: "State"
  },
  latestBatteryLevelTitle: {
    id: "modules.Layout.AdminInteractionSubscription.latestBatteryLevelTitle",
    defaultMessage: "Latest battery level"
  }
});

export const ADMIN_INTERACTION_SUBSCRIPTION = gql`
  subscription onAdminInteraction($administratorId: String!) {
    adminInteraction(administratorId: $administratorId) {
      rfid
      controllerId
      vehicleId
      administratorId
    }
  }
`;
const ADMIN_INTERACTION_QUERY = gql`
  query adminInteractionController($controllerId: ID!) {
    controller(id: $controllerId) {
      id
      batteryVoltage
      batteryStatus
      batteryCharge
      physicalLockId
      pairedEntity {
        ... on Vehicle {
          id
          state
          name
          number
          displayName {
            ... on VehicleDisplayNamed {
              name
              number
            }
            ... on VehicleDisplayNumbered {
              number
            }
            ... on VehicleDisplayAnonymous {
              id
            }
          }
          model {
            id
            vehicleCategory
          }
          latestMaintenance {
            id
            completedAt
          }
        }
      }
      model {
        id
        emptyBatteryChargeThreshold
        lowBatteryChargeThreshold
      }
    }
  }
`;

function onInteraction({
  dispatch,
  data,
  controllerData
}: {
  dispatch: any;
  data: onAdminInteractionSubscription;
  controllerData: adminInteractionControllerQuery;
}) {
  if (!data.adminInteraction) {
    return;
  }
  const { adminInteraction } = data;

  if (!adminInteraction || !controllerData || !controllerData.controller) {
    return null;
  }

  const { controller } = controllerData;
  const { pairedEntity } = controller;

  dispatch(clearNotifications());

  let vehicle:
    | adminInteractionController_controller_pairedEntity_Vehicle
    | undefined;
  if (pairedEntity?.__typename === "Vehicle") {
    vehicle = pairedEntity;
  }

  addNotificationWithTTL(
    `${
      vehicle
        ? VehicleDisplayTitle({
            displayName: vehicle.displayName
          })
        : controller.id
    } scanned`,
    {
      ttl: 60000,
      removeOnRouteChange: true
    },
    <>
      {vehicle && vehicle.latestMaintenance && (
        <SystemLink
          href={{
            pathname: "/[systemId]/maintenance/[maintenanceId]",
            query: {
              maintenanceId: vehicle.latestMaintenance.id,
              assetId:
                (!vehicle.latestMaintenance ||
                  vehicle.latestMaintenance.completedAt) &&
                vehicle.id
            }
          }}
        >
          <Button width={1} mt={5} mb={4} variant="primary">
            Open maintenance
          </Button>
        </SystemLink>
      )}
      {vehicle && (
        <SystemLink
          href={{
            pathname: "/[systemId]/maintenance/damage/[assetId]",
            query: { assetId: vehicle.id }
          }}
        >
          <ButtonAnchor width={1} mb={4} variant="secondary">
            Report damages
          </ButtonAnchor>
        </SystemLink>
      )}
      <Grid mt={5} data-testid="AdminInteraction__Grid">
        {vehicle && (
          <GridCell width={1 / 2}>
            <Info
              title={<TranslatedMessage {...messages.vehicleTitle} />}
              value={
                <VehicleDisplay
                  displayName={vehicle.displayName}
                  vehicleId={vehicle.id}
                  category={vehicle.model?.vehicleCategory}
                />
              }
            />
          </GridCell>
        )}
        {controller && (
          <GridCell width={1 / 2}>
            <Info
              title={<TranslatedMessage {...messages.controllerTitle} />}
              value={
                <ControllerLink
                  id={controller.id}
                  name={controller.physicalLockId || controller.id}
                />
              }
            />
          </GridCell>
        )}
        {vehicle && vehicle.state && (
          <GridCell mt={4} width={1 / 2}>
            <Info
              title={<TranslatedMessage {...messages.stateTitle} />}
              value={vehicle.state}
            />
          </GridCell>
        )}
        {controller && (
          <GridCell mt={4} width={1 / 2}>
            <Info
              title={
                <TranslatedMessage {...messages.latestBatteryLevelTitle} />
              }
              value={
                <BatteryLevel
                  batteryCharge={controller.batteryCharge}
                  batteryStatus={controller.batteryStatus}
                  batteryVoltage={controller.batteryVoltage}
                  emptyBatteryThreshold={
                    controller.model?.emptyBatteryChargeThreshold
                  }
                  lowBatteryThreshold={
                    controller.model?.lowBatteryChargeThreshold
                  }
                />
              }
            />
          </GridCell>
        )}
      </Grid>
    </>
  )(dispatch);
}

function AdminInteraction({ data, controllerData }) {
  const [, dispatch] = useGlobalState();
  React.useEffect(() => {
    onInteraction({ dispatch, data, controllerData });
  }, [dispatch, data, controllerData]);

  return null;
}

const AdminInteractionSubscription = ({ administratorId }: Props) => {
  const { data, loading } = useSubscription<
    onAdminInteractionSubscription,
    onAdminInteractionVariables
  >(ADMIN_INTERACTION_SUBSCRIPTION, {
    variables: { administratorId },
    skip: typeof window === "undefined"
  });
  if (
    loading ||
    !data ||
    !data.adminInteraction ||
    !data.adminInteraction.controllerId
  ) {
    return null;
  }
  return (
    <Query<adminInteractionControllerQuery, adminInteractionControllerVariables>
      query={ADMIN_INTERACTION_QUERY}
      variables={{
        controllerId: data.adminInteraction.controllerId
      }}
    >
      {({ data: controllerData }) => {
        if (!controllerData || !controllerData.controller) {
          return null;
        }
        return <AdminInteraction data={data} controllerData={controllerData} />;
      }}
    </Query>
  );
};

export default AdminInteractionSubscription;
