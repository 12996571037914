import React from "react";
import { FormattedMessage, defineMessages } from "react-intl";
import styled from "styled-components";
import Link from "next/link";
import {
  Arrow,
  Avatar,
  Box,
  Button,
  Dropdown,
  DropdownCaption,
  DropdownItem,
  Flex,
  H2,
  ModalRenderer,
  Text,
  SystemLink
} from "components";
import { SSO_ENDPOINT, BASE_URL } from "../../../src/constants";
import * as Acl from "../../../src/utils/acl";
import { usePlatformAdminCheck } from "../../../src/hooks";
import ViewAs from "./ViewAs";

import { globalData as globalDataQuery } from "../../../src/core-types";

type Props = {
  administrator: globalDataQuery["administrator"];
  systemId: string;
  showInMainMenu?: boolean;
  isOpen: boolean;
  onClick: () => void;
};

type DropDownItem = {
  key: string;
  pathname: Pathname;
};

const messages = defineMessages({
  logout: {
    id: "Profile.nav.logout",
    defaultMessage: "Log out"
  },
  signedinas: {
    id: "Profile.nav.logedin",
    defaultMessage: "Signed in as"
  },
  settings: {
    id: "Profile.nav.settings",
    defaultMessage: "Settings"
  },
  exports: {
    id: "Profile.nav.exports",
    defaultMessage: "My exports"
  }
});

const ProfileButton = styled(Button)`
  background-color: transparent;
  border: 0;
  padding: 0;
  width: 100%;
  &:hover,
  &:focus {
    cursor: pointer;
  }
  &:active {
    background: transparent;
  }
`;

const DropdownContainer = styled(Flex)`
  position: relative;
`;

const ArrowDown = () => (
  <Arrow direction="down" size="8px" color="text" ml={2} />
);

export default function Profile(props: Props) {
  const isPlatformAdmin = usePlatformAdminCheck();
  const { systemId, administrator, showInMainMenu, isOpen, onClick } = props;
  if (!administrator) {
    return null;
  }
  const dropdownItems: DropDownItem[] = [];

  if (administrator) {
    const { roles } = administrator;
    const canViewSettings =
      Acl.can(systemId, "role", "read", roles) &&
      Acl.can(systemId, "administrator", "read", roles);

    if (canViewSettings) {
      dropdownItems.unshift({
        pathname: "/settings/systems",
        key: "settings"
      });
    }
  }
  return (
    <DropdownContainer alignItems="center">
      <Flex alignItems="center" width={1}>
        <Box width={1}>
          <ProfileButton
            color="white"
            onClick={onClick}
            aria-label="Toggle settings menu"
            title={administrator.name || administrator.email || undefined}
          >
            {showInMainMenu ? (
              <Flex
                alignItems="center"
                bg="neutral.2"
                width={1}
                pr={2}
                justifyContent="space-between"
                borderRadius="6px"
              >
                <Flex alignItems="center">
                  {administrator.profilePictureUrl && (
                    <Box mr={2}>
                      <Avatar
                        width="25px"
                        src={administrator.profilePictureUrl}
                      />
                    </Box>
                  )}
                  <Box m={1} color="text" textAlign="left">
                    {administrator.name || administrator.email}
                  </Box>
                </Flex>
                <ArrowDown />
              </Flex>
            ) : (
              <Flex
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                height="32px"
                width="32px"
                backgroundColor="white"
              >
                {administrator.profilePictureUrl ? (
                  <Box>
                    <Avatar src={administrator.profilePictureUrl} />
                  </Box>
                ) : (
                  <Box fontWeight="700">
                    {administrator.name?.substring(0, 1) ?? "U"}
                  </Box>
                )}
              </Flex>
            )}
          </ProfileButton>
        </Box>
      </Flex>
      <Dropdown isOpen={isOpen} mt={2}>
        <DropdownCaption>
          <Text fontSize={0}>
            <FormattedMessage {...messages["signedinas"]} />:
          </Text>
          {administrator.name || administrator.email}
        </DropdownCaption>
        {/* Until viewAs is fixed */}
        <ModalRenderer
          closeOnOutsideClick
          render={({ closePortal }) => {
            return (
              <div>
                <H2>Debug</H2>
                <Box mb={4}>
                  <ViewAs currentAdminId={administrator.id} viewAsId={null} />
                </Box>
                <Button onClick={closePortal}>Close</Button>
              </div>
            );
          }}
          renderToggler={({ openPortal }) =>
            isPlatformAdmin ? (
              <DropdownItem href="#" onClick={openPortal}>
                Open debug
              </DropdownItem>
            ) : null
          }
        />
        <DropdownItem
          href={`${SSO_ENDPOINT}/auth/logout?redirect=${BASE_URL}/`}
        >
          <FormattedMessage {...messages.logout} />
        </DropdownItem>
        <SystemLink href="/[systemId]/exports" prefetch={false}>
          <DropdownItem>
            <FormattedMessage {...messages.exports} />
          </DropdownItem>
        </SystemLink>
        {dropdownItems.map((item, i) => (
          <Link key={i} href={item.pathname} passHref prefetch={false}>
            <DropdownItem>
              <FormattedMessage {...messages[item.key]} />
            </DropdownItem>
          </Link>
        ))}
      </Dropdown>
    </DropdownContainer>
  );
}
