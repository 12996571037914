import React, { Ref } from "react";
import styled from "styled-components";

import NavLink from "./NavLink";
import Profile from "../NavBar/Profile";
import routeMessages from "../../../src/routes/messages";
import SystemPicker from "../NavBar/SystemPicker";
import {
  List,
  Li,
  Hide,
  Box,
  RouteIcon,
  Text,
  Popover,
  SystemLink
} from "components";
import { useEventTracking, useTranslate } from "../../../src/hooks";
import { RouteModel } from "../../../src/types";
import { globalData as globalDataQuery } from "../../../src/core-types";

const ListItem = React.forwardRef<
  HTMLAnchorElement,
  {
    route: Pathname;
    active: boolean;
    menuIsOpen: boolean;
  }
>(function ListItemComponent({ route, menuIsOpen, active, ...props }, ref) {
  const translate = useTranslate();
  const trackEvent = useEventTracking();
  const routeName = translate(routeMessages[route]);

  const link = (
    <NavLink
      ref={ref}
      active={active}
      aria-label={routeName}
      menuIsOpen={menuIsOpen}
      onClick={() => {
        trackEvent({
          action: "Clicked first level item",
          category: "Main menu",
          label: routeName
        });
      }}
      {...props}
    >
      <RouteIcon
        route={route}
        mr={3}
        style={{ verticalAlign: "middle" }}
        color={active ? "primary" : "neutral.4"}
      />
      <Text as="span">{routeName}</Text>
    </NavLink>
  );

  return <SystemLink href={route}>{link}</SystemLink>;
});
ListItem.displayName = "ListItem";

const MenuList = styled(List)`
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overflow-x: visible;
  max-height: 100%;

  &::-webkit-scrollbar {
    width: 1px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.neutral[4]};
  }
`;

const MainMenu = ({
  routes,
  pathName,
  administrator,
  systems,
  systemId,
  profileIsOpen,
  systemPickerIsOpen,
  toggleSystemPickerDropdown,
  toggleProfileDropdown,
  menuIsOpen,
  mainMenuOnMobileIsOpen
}: {
  pathName: string;
  routes: RouteModel[];
  administrator: globalDataQuery["administrator"];
  systems: globalDataQuery["systems"];
  systemId: string;
  profileIsOpen: boolean;
  systemPickerIsOpen: boolean;
  toggleSystemPickerDropdown: () => void;
  toggleProfileDropdown: () => void;
  menuIsOpen: boolean;
  mainMenuOnMobileIsOpen: boolean;
}) => {
  const translate = useTranslate();
  const isRoot = pathName === "/[systemId]";
  return (
    <MenuList data-testid="navigation">
      {routes.map(({ route, isStartPage }) => {
        const isCurrentRoute = pathName === route;
        const isRootOfCurrentRoute =
          pathName === route && route !== "/[systemId]";
        const active =
          isRoot && isStartPage ? true : isCurrentRoute || isRootOfCurrentRoute;

        if (!routeMessages[route]) {
          // eslint-disable-next-line no-console
          console.error(
            `Route ${route} have no defined intl message, please define one in src/routes/messages.js`
          );
          return null;
        }

        return (
          <Li key={route}>
            {menuIsOpen || mainMenuOnMobileIsOpen ? (
              <ListItem route={route} menuIsOpen={menuIsOpen} active={active} />
            ) : (
              <Popover
                placement="left-end"
                trigger="hover"
                width="auto"
                modifiers={{
                  preventOverflow: {
                    enabled: false
                  },
                  hide: {
                    enabled: false
                  }
                }}
                renderToggler={({ ref, ...renderTogglerProps }) => {
                  return (
                    <ListItem
                      ref={ref as Ref<HTMLAnchorElement>}
                      route={route}
                      menuIsOpen={menuIsOpen}
                      active={active}
                      {...renderTogglerProps}
                    />
                  );
                }}
              >
                {() => {
                  return (
                    <Text whiteSpace="nowrap">
                      {translate(routeMessages[route])}
                    </Text>
                  );
                }}
              </Popover>
            )}
          </Li>
        );
      })}
      <Hide lg md xl>
        <Box m={3} mt={6} mb={6}>
          {systems.length > 1 && (
            <Box mb={6}>
              <SystemPicker
                id="NavBar__SystemPicker__Mobile"
                data-testid="NavBar__SystemPicker__Mobile"
                administrator={administrator}
                systems={systems}
                systemId={systemId}
                showInMainMenu={true}
                isOpen={systemPickerIsOpen}
                onClick={toggleSystemPickerDropdown}
              />
            </Box>
          )}
          <Box>
            <Profile
              systemId={systemId}
              administrator={administrator}
              showInMainMenu={true}
              isOpen={profileIsOpen}
              onClick={toggleProfileDropdown}
            />
          </Box>
        </Box>
      </Hide>
    </MenuList>
  );
};

export default MainMenu;
