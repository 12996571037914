import React, { useState } from "react";
import {
  RemoveAlt as CloseIcon,
  Qr as QrIcon
} from "@urbaninfrastructure/react-icons";

import { Alert, Box, Button, Flex, H2, Hide, ModalRenderer } from "components";
import { useSystemRouter } from "../../../src/hooks";
import {
  vehicleByQrCode,
  vehicleByQrCodeVariables
} from "../../../src/core-types";
import { useQuery } from "@apollo/react-hooks";
import { VEHICLE_BY_QR_CODE } from "../../../src/queries";
import messages from "./messages";
if (typeof window != "undefined") {
  // eslint-disable-next-line @typescript-eslint/no-var-requires, no-var
  var QrReader = require("modern-react-qr-reader");
}

export const QrReaderModal = ({ systemId }: { systemId: string }) => {
  const [qrScanError, setQrScanError] = useState<string>("");
  const [qrCode, setQrCode] = useState<string>("");
  const { pushRoute } = useSystemRouter();

  const handleError = () => {
    setQrScanError(messages.cannotActivateCamera.defaultMessage);
  };

  const { data, loading, refetch } = useQuery<
    vehicleByQrCode,
    vehicleByQrCodeVariables
  >(VEHICLE_BY_QR_CODE, {
    variables: { qrCode: qrCode, systemId: systemId }
  });

  const handleScan = async (scannedQrCode: string, closePortal) => {
    if (qrScanError === messages.cannotActivateCamera.defaultMessage) {
      setQrScanError("");
    }
    if (scannedQrCode) {
      setQrCode(scannedQrCode);
      if (qrCode && qrCode === scannedQrCode) {
        await refetch();
        if (data?.vehicleByQrCode?.id) {
          setQrScanError("");
          pushRoute({
            pathname: `/[systemId]/vehicles/[vehicleId]`,
            query: { vehicleId: data.vehicleByQrCode.id }
          });
          closePortal();
        } else {
          if (!loading) {
            setQrScanError(messages.vehicleNotFound.defaultMessage);
          }
        }
      }
    }
  };

  return (
    <ModalRenderer
      render={({ closePortal }) => {
        return (
          <>
            <Button
              onClick={() => {
                closePortal();
                setQrCode("");
              }}
              aria-label="Close"
              variant="reset"
              style={{ float: "right" }}
            >
              <CloseIcon />
            </Button>

            <H2>Scan QR Code</H2>

            {qrScanError !== "" && (
              <Alert variant="error" mb={4}>
                {qrScanError}
              </Alert>
            )}

            {typeof window != "undefined" && (
              <QrReader
                delay={50}
                facingMode={"environment"}
                onError={handleError}
                onScan={data => {
                  handleScan(data, closePortal);
                }}
                style={{
                  width: "90%",
                  marginLeft: "auto",
                  marginRight: "auto"
                }}
              />
            )}
          </>
        );
      }}
      closeOnOutsideClick={true}
      renderToggler={({ openPortal }) => (
        <Box>
          <Button shape="normal" variant="primary" mr={2} onClick={openPortal}>
            <Flex alignItems="center">
              <QrIcon size="20px" mr={1} style={{ verticalAlign: "sub" }} />
              <Hide xs sm md lg>
                Scan QR Code
              </Hide>
            </Flex>
          </Button>
        </Box>
      )}
    />
  );
};
