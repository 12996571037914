import React from "react";
import styled, { keyframes, css } from "styled-components";

import { Flex, Box, Heading, Button, Icon, Hide } from "components";

export type PageHeaderProps = {
  heading?: React.ReactNode;
  headingIcon?: JSX.Element;
  name?: string;
  hideHeading?: boolean;
  back?: {
    route: string;
    params?: Record<string, any>;
    children: React.ReactNode;
  };
  actions?: React.ReactNode;
  fluid?: boolean;
  bleed?: boolean;
};

type State = {
  toggleActions: boolean;
};

const dropdownAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-15px)
  }
  to {
    opacity:1;
    transform: translateY(1px)
  }
`;

const actionsWrapperCss = ({ theme, toggleActions }) => css`
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    display: none;
    ${toggleActions &&
      css`
        display: block;
        float: left;
        width: calc(100% + ${theme.space[4]}px + ${theme.space[4]}px);
        animation: 100ms ease-out ${dropdownAnimation};
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        background-color: ${theme.colors.neutral[1]};
        border-top: 1px solid ${theme.colors.neutral[2]};
        border-bottom: 1px solid ${theme.colors.neutral[2]};
        padding: ${theme.space[4]}px;
        margin-top: ${theme.space[4]}px;
        margin-left: -${theme.space[4]}px;
        margin-right: -${theme.space[4]}px;
      `};
  }
`;

const toggleButtonCss = ({ theme }) => css`
  display: none;
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    display: block;
    padding: ${theme.space[2]}px;
    border-radius: 50%;
  }
`;

const ActionsWrapper = styled.div`
  ${actionsWrapperCss};
`;

const ToggleButton = styled(Button)`
  ${toggleButtonCss};
`;

export default class PageHeader extends React.Component<
  PageHeaderProps,
  State
> {
  props: PageHeaderProps;
  state: State = { toggleActions: false };

  toggleActions() {
    this.setState({ toggleActions: !this.state.toggleActions });
  }

  render() {
    const { heading, hideHeading, actions, headingIcon } = this.props;
    const { toggleActions } = this.state;

    let headingComponent;

    if (heading) {
      headingComponent = heading;

      if (hideHeading) {
        headingComponent = <Hide srOnly>{headingComponent}</Hide>;
      }
    }

    return (
      <Flex
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        pt={4}
        mb={[5, 6]}
      >
        <Box width={"auto"}>
          {headingComponent && (
            <Heading mb={0} data-testid="page-header-heading">
              {headingComponent}
              {headingIcon}
            </Heading>
          )}
        </Box>
        {actions && (
          <React.Fragment>
            <ToggleButton
              small
              variant="secondary"
              type="button"
              onClick={() => this.toggleActions()}
              aria-expanded={toggleActions}
              aria-label={toggleActions ? "Show options" : "Hide options"}
              title={toggleActions ? "Show options" : "Hide options"}
            >
              <Icon
                name={toggleActions ? "up" : "more"}
                size="12px"
                color="primary"
                style={{ verticalAlign: "top", display: "block" }}
              />
            </ToggleButton>
            <ActionsWrapper toggleActions={toggleActions}>
              {actions}
            </ActionsWrapper>
          </React.Fragment>
        )}
      </Flex>
    );
  }
}
