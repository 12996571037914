import React, { useCallback } from "react";
import * as Sentry from "@sentry/browser";
import { useRouter } from "next/router";
import {
  Button,
  ButtonGroup,
  LoadableButton,
  Text,
  Dialog,
  AnchorButton,
  Alert
} from "@urbaninfrastructure/react-ui-kit";
import { ChatAlt as ChatIcon } from "@urbaninfrastructure/react-icons";
import { Label, Textarea } from "../";
import { globalData_administrator } from "../../src/core-types";
import { BASE_URL } from "../../src/constants";
import { useSystem } from "../../src/hooks";

const cloudFunctionUrl =
  "https://europe-west1-uip-production.cloudfunctions.net/admin-jira-feedback";

export const SlackFeedbackForm = (props: {
  administrator: globalData_administrator;
  version: string;
  gitsha: string;
}) => {
  const system = useSystem();
  const [description, setDescription] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [feedbackReceived, setFeedbackReceived] = React.useState(false);
  const router = useRouter();

  const { administrator, version, gitsha } = props;

  const url = router.asPath;
  const systemId = administrator.selectedSystem.id;
  const administratorName = administrator.name || administrator.id || "";

  const sendFeedback = useCallback(
    () =>
      fetch(cloudFunctionUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          version,
          name:
            description.length > 60
              ? `${description.substring(0, 60).trim()} ...`
              : description,
          description: `${description}
Administrator: ${administratorName}
URL: ${BASE_URL}${url}
System ID: ${systemId}
Version: ${version}
gitsha: ${gitsha}
        `,
          systemId
        })
      })
        .then(response => {
          if (response.status >= 400) {
            throw new Error("Bad response from server");
          }
          return response.text();
        })
        .then(() => {
          setLoading(false);
          setFeedbackReceived(true);
        })
        .catch(err => {
          Sentry.captureException(err);
          setError("Could not submit feedback");
          setLoading(false);
          setFeedbackReceived(false);
        }),
    [administratorName, description, gitsha, systemId, url, version]
  );

  if (system.settings.disableAdminFeedbackForm) {
    return null;
  }

  return (
    <Dialog
      heading="Urban Sharing Feedback"
      renderOpenButton={openPortal => {
        return (
          <AnchorButton onClick={openPortal} textDecoration="none">
            <ChatIcon />
            Feedback
          </AnchorButton>
        );
      }}
    >
      {({ closePortal }) => {
        if (feedbackReceived) {
          return (
            <>
              <Text mt="xs" mb="xs">
                Thank you for your feedback. We read and appreciate it! We will
                contact you if we need additional information.
                <br />
                <br />
                Best regards, <br />
                The Urban Sharing Team
              </Text>
              <Button
                onClick={() => {
                  closePortal();
                  setFeedbackReceived(false);
                  setDescription("");
                }}
                variant="primary"
              >
                Close
              </Button>
            </>
          );
        }
        return (
          <>
            {error && (
              <Alert variant="error" mb="xs">
                {error}
              </Alert>
            )}
            <Text mb="xs">
              We read and appreciate all feedback. We will contact you if we
              need additional information.
            </Text>
            <Label htmlFor="description">
              Describe your issue or share your ideas
            </Label>
            <Textarea
              name="description"
              id="description"
              mb="xs"
              value={description}
              onChange={event => setDescription(event.target.value)}
              rows={5}
              placeholder="If you're reporting a problem, please include what you did, what you expected and what happened."
              autoFocus
            />
            <ButtonGroup mt="xs">
              <LoadableButton
                loading={loading}
                onClick={() => {
                  setLoading(true);
                  sendFeedback();
                }}
                variant="primary"
                responseState={error ? "error" : "success"}
              >
                Send feedback
              </LoadableButton>
              <Button variant="link" onClick={() => closePortal()}>
                Cancel
              </Button>
            </ButtonGroup>
          </>
        );
      }}
    </Dialog>
  );
};
