import styled, { css } from "styled-components";
import { Text } from "components";
import { UIKitTheme as Theme } from "@urbaninfrastructure/react-ui-kit";

type Props = {
  active: boolean;
  menuIsOpen?: boolean;
};

type StyledProps = Props & {
  theme: Theme;
};

const isActiveCss = ({ active, theme }: StyledProps) =>
  active &&
  css`
    color: ${theme.colors.text};
    background-color: ${theme.colors.selectedBackground};
  `;

const showTextCss = ({ menuIsOpen, theme }: StyledProps) =>
  !menuIsOpen &&
  css`
    ${theme.mediaQueries[3]} {
      overflow: hidden;
      white-space: nowrap;
      ${Text} {
        display: none;
      }
    }
  `;

const NavLink = styled(Text.withComponent("a"))`
  text-decoration: none;
  display: flex;
  position: relative;
  border: 0;
  transition: all 100ms ease;
  border-radius: ${({ theme }) => theme.radii["md"]};
  background: transparent;
  color: ${({ theme }) => theme.colors.text};
  svg {
    transition: color 100ms ease;
  }
  &:hover,
  &:focus,
  &:active {
    background: ${({ theme }) => theme.colors.neutral[2]};
  }
  ${isActiveCss};
  ${showTextCss}
`;

NavLink.displayName = "NavLink";

NavLink.defaultProps = {
  p: 3,
  m: 2
};

export default NavLink;
