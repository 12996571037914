import gql from "graphql-tag";

export const TRIPS_THIS_YEAR = gql`
  query dashboardTripsThisYear($systemId: ID!) {
    tripsThisYear(systemId: $systemId)
  }
`;

export const DASHBOARD_NUMBERS = gql`
  query dashboardNumbers($systemId: ID!) {
    dashboard(systemId: $systemId) {
      vehicles
      controllers
      users
      vehiclesTaggedExceeding
    }
  }
`;

export const DOCK_GROUP_STATISTICS = gql`
  query dashboardDockGroupStatistics($systemId: ID!) {
    dockGroupStatistics(systemId: $systemId) {
      totalActiveDocks
      totalBrokenDocks
      totalMaintenanceDockGroups
      totalNotInServiceDockGroups
      totalOnlineActiveDockGroups
      totalUnresponsiveActiveDockGroups
    }
  }
`;

export const ACTIVE_TRIP_COUNT = gql`
  query dashboardSystemActiveTripCount($systemId: ID!) {
    systemActiveTripCount(systemId: $systemId) {
      count
    }
  }
`;

export const REBALANCING_STATISTICS = gql`
  query dashboardRebalancingStatistics($systemId: ID!) {
    rebalancingStatistics: dockGroupStatistics(
      systemId: $systemId
      filters: { state: [active] }
    ) {
      totalBalancedDockGroups
      totalDockGroupsWithoutAvailableDocks
      totalDockGroupsWithoutAvailableVehicles
    }
  }
`;

export const OPERATION_LOCATIONS_STATISTICS = gql`
  query dashboardOperationLocationsStatistics($systemId: ID!) {
    operationLocationsStatistics(systemId: $systemId) {
      id
      name
      vehicleCount
    }
  }
`;

export const MISPLACED_VEHICLES = gql`
  query dashboardMisplacedVehicles($systemId: ID!) {
    misplacedVehicles(systemId: $systemId, maxDistanceInMeters: 100) {
      id
    }
  }
`;

export const ACTIVE_SYSTEM_ALERTS = gql`
  query dashboardActiveSystemAlerts($systemId: ID!) {
    activeSystemAlerts(systemId: $systemId) {
      id
      incidentStartTime
      title {
        language
        text
      }
      description {
        language
        text
      }
      visibility
    }
  }
`;

export const VEHICLE_INVENTORY_MAS = gql`
  query dashboardVehicleInventoryMas($systemId: ID!) {
    vehicleInventory(systemId: $systemId) {
      vehiclesInService {
        count
      }
      vehiclesOnStreet {
        count
      }
      vehiclesInStation {
        count
      }
      vehiclesOnTrip {
        count
      }
      vehiclesWithOperations {
        count
      }
      vehiclesInOperationLocation {
        count
      }
      vehiclesInFreeFloatingLocation {
        count
      }
      vehiclesAvailableInFreeFloatingLocation {
        count
      }
      vehiclesUnavailableInFreeFloatingLocation {
        count
      }
      vehiclesAvailabilityUnknown {
        count
      }
      vehiclesAvailableInStation {
        count
      }
      vehiclesUnavailableInStation {
        count
      }
    }
  }
`;

export const VEHICLES_INVENTORY_BATTERY = gql`
  query dashboardVehiclesInventoryBattery($systemId: ID!) {
    vehicleInventory(systemId: $systemId) {
      vehiclesOnStreetBatteryOk {
        count
      }
      vehiclesOnStreetBatteryLow {
        count
      }
      vehiclesOnStreetBatteryDead {
        count
      }
      vehiclesOnStreetControllerBatteryOk {
        count
      }
      vehiclesOnStreetControllerBatteryLow {
        count
      }
      vehiclesOnStreetControllerBatteryDead {
        count
      }
    }
  }
`;

export const VEHICLE_MISSING_LOST = gql`
  query dashboardVehicleMissingLost($systemId: ID!) {
    vehicleInventory(systemId: $systemId) {
      vehiclesAvailabilityUnknown {
        count
      }
      vehiclesLost {
        count
      }
    }
  }
`;
