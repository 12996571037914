import React, { useEffect, useState } from "react";
import NextLink from "next/link";
import styled, { css } from "styled-components";
import { FormattedMessage, FormattedTime, useIntl } from "react-intl";
import { UrbanSharingLogo, Grid, GridCell, Text, Anchor } from "components";
import {
  List as ReleasesIcon,
  ProfileAlt as PrivacyPolicyIcon
} from "@urbaninfrastructure/react-icons";

import OperationalStatus from "../../components/OperationalStatus";

import {
  system as systemQuery,
  globalData_administrator
} from "../../src/core-types";

import { PRIVACY_POLICY_URL } from "../../src/constants";
import FeedbackForm from "components/FeedbackForm";

const BreakText = styled(Text)`
  overflow-wrap: break-word;
`;

type Props = {
  system: systemQuery["system"];
  administrator: globalData_administrator;
  asPath: string;
  version: string;
  gitsha: string;
};

const monoCss = ({ theme }) => css`
  display: inline-block;
  font: mono;
  padding: 0 ${theme.space[1]}px;
  background: ${theme.colors.neutral[1]};
  color: ${theme.colors.text};
  font-size: ${theme.fontSizes[1]}px;
`;

const Mono = styled.span`
  ${monoCss};
`;

const logoWrapperCss = ({ theme }) => css`
  display: flex;
  justify-content: center;
  padding-bottom: ${theme.space[4]}px;
  svg {
    width: 100%;
    max-width: 10rem;
  }
`;

const LogoWrapper = styled.div`
  ${logoWrapperCss};
`;

const featureLinkWrapperCss = ({ theme }) => css`
  svg {
    vertical-align: middle;
    margin-right: ${theme.space[2]}px;
    color: ${theme.colors.neutral[5]};
    width: ${theme.space[5]}px;
  }
  a {
    display: inline-block;
    text-decoration: none;
    padding: ${theme.space[2]}px;
    border-radius: ${theme.radii.md};
    transition: background-color 200ms ease;
    &:hover,
    &:focus {
      background-color: ${theme.colors.neutral[1]};
      color: ${theme.colors.primary};
      svg {
        color: ${theme.colors.primary};
      }
    }
  }
`;

export const FeatureLinkWrapper = styled.span`
  ${featureLinkWrapperCss};
`;

export const TimeZone = ({ small }: { small?: boolean }) => {
  const intl = useIntl();
  return (
    <BreakText small={small}>
      Timezone: <Mono>{intl.timeZone}</Mono>
    </BreakText>
  );
};

export const LocalTime = ({ small }: { small?: boolean }) => {
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setNow(new Date());
    }, 1000 * 5);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  const dateTime = now.toISOString();
  return (
    <BreakText small={small}>
      Local time:{" "}
      <time dateTime={dateTime} title={dateTime}>
        <FormattedTime
          value={now}
          hour="numeric"
          minute="numeric"
          hour12={false}
        />
      </time>
    </BreakText>
  );
};

const Footer = ({ version, gitsha, system }: Props) => {
  return (
    <>
      <Grid
        flexDirection={["column", "row"]}
        alignItems={["flex-start", "center"]}
        justifyContent={"center"}
        my={5}
      >
        <GridCell width={"auto"}>
          <FeatureLinkWrapper>
            <OperationalStatus
              statusPageId="ks3dbwg9d3gg"
              style={{ display: "flex", justifyContent: "center" }}
            />
          </FeatureLinkWrapper>
        </GridCell>

        {!system.settings.disableAdminFeedbackForm && (
          <GridCell width={"auto"}>
            <FeatureLinkWrapper>
              <FeedbackForm version={version} gitsha={gitsha} />
            </FeatureLinkWrapper>
          </GridCell>
        )}

        <GridCell width={"auto"}>
          <FeatureLinkWrapper>
            <NextLink href="/releases" passHref>
              <Anchor>
                <ReleasesIcon />
                <FormattedMessage
                  id="components.Footer.releases"
                  defaultMessage="Releases"
                />
              </Anchor>
            </NextLink>
          </FeatureLinkWrapper>
        </GridCell>

        <GridCell width={"auto"}>
          <FeatureLinkWrapper>
            <Anchor
              href={PRIVACY_POLICY_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PrivacyPolicyIcon />
              <FormattedMessage
                id="components.Footer.privacyPolicy"
                defaultMessage="Privacy policy"
              />
            </Anchor>
          </FeatureLinkWrapper>
        </GridCell>
      </Grid>

      <Grid
        flexDirection={["column", "row"]}
        alignItems={"center"}
        justifyContent={["center"]}
        my={5}
      >
        <GridCell width={"auto"}>
          <BreakText>
            System ID: <Mono>{system.id}</Mono>
          </BreakText>
        </GridCell>

        <GridCell width={"auto"}>
          <TimeZone />
        </GridCell>

        <GridCell width={"auto"}>
          <LocalTime />
        </GridCell>

        <GridCell width={"auto"}>
          <BreakText>
            Admin:{" "}
            <a
              href={`https://github.com/urbaninfrastructure/admin/commit/${gitsha}`}
            >
              <Mono>{version}</Mono>
            </a>
          </BreakText>
        </GridCell>
      </Grid>

      <LogoWrapper>
        <a href="https://urbansharing.com/">
          <UrbanSharingLogo />
        </a>
      </LogoWrapper>

      <Text my={3} textAlign="center">
        Made with &#x2764; in Norway
      </Text>
    </>
  );
};

export default Footer;
