import * as Acl from "../acl";
import { globalData_administrator, AclEntity } from "../../core-types";
import { RouteModel } from "../../../src/types";

function canAccessEntities(entities: Array<string>, acl: string): boolean {
  return entities.includes("any") || entities.includes(acl);
}

export function filterRoutesByRoles(
  systemId: string,
  routes: RouteModel[],
  roles: globalData_administrator["roles"]
): RouteModel[] {
  const allowedEntities = routes.reduce<AclEntity[]>((accum, { aclEntity }) => {
    if (aclEntity) {
      if (Array.isArray(aclEntity)) {
        aclEntity.forEach(a => {
          if (Acl.can(systemId, a, "read", roles)) {
            accum.push(a);
          }
        });
      } else if (Acl.can(systemId, aclEntity, "read", roles)) {
        accum.push(aclEntity);
      }
    }
    return accum;
  }, []);
  return routes.filter(({ aclEntity }) => {
    if (aclEntity) {
      if (Array.isArray(aclEntity)) {
        return aclEntity.some(acl => canAccessEntities(allowedEntities, acl));
      } else {
        return canAccessEntities(allowedEntities, aclEntity);
      }
    }
    return true;
  });
}
