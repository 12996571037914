import styled from "styled-components";
import { Flex } from "components";

const CenteredContainer = styled(Flex)`
  min-height: 100vh;
`;
CenteredContainer.defaultProps = {
  alignItems: "center",
  justifyContent: "center"
};

export default CenteredContainer;
