import React from "react";
import gql from "graphql-tag";
import { Mutation, MutationComponentOptions } from "@apollo/react-components";

import * as fragments from "./fragments";
import {
  updateAdministratorMutation,
  updateAdministratorMutationVariables,
  deleteTag as deleteTagMutation,
  deleteTagVariables as deleteTagMutationVariables
} from "./core-types";

// Dock groups
export const deleteDockGroup = gql`
  mutation deleteDockGroup($id: ID!) {
    deleteDockGroup(id: $id) {
      id
    }
  }
`;

export const createDockGroup = gql`
  mutation createDockGroup($input: DockGroupCreateInput) {
    createDockGroup(input: $input) {
      id
      name
      title
    }
  }
`;

export const updateDockGroup = gql`
  mutation updateDockGroup($id: ID!, $input: DockGroupUpdateInput) {
    updateDockGroup(id: $id, input: $input) {
      id
      name
    }
  }
`;

// Docks
export const deleteDock = gql`
  mutation deleteDock($id: ID!) {
    deleteDock(id: $id) {
      id
    }
  }
`;

export const createDock = gql`
  mutation createDock($systemId: ID!, $dockGroupId: ID!, $input: DockInput) {
    createDock(systemId: $systemId, dockGroupId: $dockGroupId, input: $input) {
      id
    }
  }
`;

export const updateDock = gql`
  mutation updateDock($id: ID!, $input: DockInput) {
    updateDock(id: $id, input: $input) {
      id
      number
    }
  }
`;

// Comments
export const addComment = gql`
  mutation addComment($entityKind: Entity!, $entityId: ID!, $body: String!) {
    addComment(entityKind: $entityKind, entityId: $entityId, body: $body) {
      id
      body
    }
  }
`;

// Vehicle comments
export const addVehicleComment = gql`
  mutation addVehicleComment(
    $vehicleId: ID!
    $administratorId: ID!
    $comment: String
  ) {
    addVehicleComment(
      vehicleId: $vehicleId
      administratorId: $administratorId
      comment: $comment
    ) {
      id
      comment
    }
  }
`;

export const ADD_TRIP_COMMENT_MUTATION = gql`
  mutation addTripComment($tripId: ID!, $comment: String!) {
    addTripComment(tripId: $tripId, comment: $comment) {
      id
      comment
    }
  }
`;

export const ADD_DOCK_GROUP_COMMENT_MUTATION = gql`
  mutation addDockGroupComment($dockGroupId: ID!, $comment: String!) {
    addDockGroupComment(dockGroupId: $dockGroupId, comment: $comment) {
      id
      comment
    }
  }
`;

// Orders
export const cancelOrder = gql`
  mutation cancelOrder($orderId: ID!, $reason: String!) {
    cancelOrder(orderId: $orderId, reason: $reason) {
      id
    }
  }
`;

// Systems

export const deleteSystem = gql`
  mutation deleteSystem($id: ID!) {
    deleteSystem(id: $id) {
      id
    }
  }
`;

// Roles
export const createRole = gql`
  mutation createRole($input: RoleInput) {
    createRole(input: $input) {
      id
    }
  }
`;

export const deleteRole = gql`
  mutation deleteRole($id: ID!) {
    deleteRole(id: $id) {
      id
    }
  }
`;

export const updateRole = gql`
  mutation updateRole($id: ID!, $input: RoleInput) {
    updateRole(id: $id, input: $input) {
      id
    }
  }
`;

// Administrators
export const UPDATE_ADMINISTRATOR_MUTATION = gql`
  ${fragments.administrator}
  ${fragments.system}
  mutation updateAdministratorMutation($id: ID!, $input: AdministratorInput) {
    updateAdministrator(id: $id, input: $input) {
      ...Administrator
      selectedSystem {
        ...System
      }
    }
  }
`;

export function UpdateAdministrator(
  props: Omit<
    MutationComponentOptions<
      updateAdministratorMutation,
      updateAdministratorMutationVariables
    >,
    "mutation"
  >
) {
  return (
    <Mutation<updateAdministratorMutation, updateAdministratorMutationVariables>
      mutation={UPDATE_ADMINISTRATOR_MUTATION}
      {...props}
    />
  );
}

// Tags
export const createTag = gql`
  mutation createTag($input: TagInput) {
    createTag(input: $input) {
      id
      name
      colour
    }
  }
`;
export const updateTag = gql`
  mutation updateTag($id: ID!, $input: TagInput) {
    updateTag(id: $id, input: $input) {
      id
      name
      colour
    }
  }
`;
export const deleteTag = gql`
  mutation deleteTag($id: ID!) {
    deleteTag(id: $id) {
      id
    }
  }
`;
export function DeleteTagMutation(
  props: Omit<
    MutationComponentOptions<deleteTagMutation, deleteTagMutationVariables>,
    "mutation"
  >
) {
  return (
    <Mutation<deleteTagMutation, deleteTagMutationVariables>
      mutation={deleteTag}
      {...props}
    />
  );
}

// Asset Model
export const updateAssetModel = gql`
  mutation updateAssetModel($id: ID!, $input: AssetModelUpdateInput) {
    updateAssetModel(id: $id, input: $input) {
      id
    }
  }
`;
export const createAssetModel = gql`
  mutation createAssetModel($input: AssetModelCreateInput) {
    createAssetModel(input: $input) {
      id
    }
  }
`;

// Asset Damage Type
export const updateAssetDamageType = gql`
  mutation updateAssetDamageType($id: ID!, $input: AssetDamageTypeInput) {
    updateAssetDamageType(id: $id, input: $input) {
      id
    }
  }
`;
export const deleteAssetDamageType = gql`
  mutation deleteAssetDamageType($id: ID!) {
    deleteAssetDamageType(id: $id) {
      id
    }
  }
`;
export const createAssetDamageType = gql`
  mutation createAssetDamageType($input: AssetDamageTypeInput) {
    createAssetDamageType(input: $input) {
      id
    }
  }
`;

// Asset Part
export const updateAssetPart = gql`
  mutation updateAssetPart($id: ID!, $input: AssetPartInput) {
    updateAssetPart(id: $id, input: $input) {
      id
      model {
        id
        type
        name
        description
      }
      name
      order
      updatedAt
    }
  }
`;
export const deleteAssetPart = gql`
  mutation deleteAssetPart($id: ID!) {
    deleteAssetPart(id: $id) {
      id
    }
  }
`;
export const createAssetPart = gql`
  mutation createAssetPart($input: AssetPartInput) {
    createAssetPart(input: $input) {
      id
      model {
        id
        type
        name
        description
      }
      name
      order
      updatedAt
    }
  }
`;

// Asset Repair Type
export const updateAssetRepairType = gql`
  mutation updateAssetRepairType($id: ID!, $input: AssetRepairTypeInput) {
    updateAssetRepairType(id: $id, input: $input) {
      id
    }
  }
`;
export const deleteAssetRepairType = gql`
  mutation deleteAssetRepairType($id: ID!) {
    deleteAssetRepairType(id: $id) {
      id
    }
  }
`;
export const createAssetRepairType = gql`
  mutation createAssetRepairType($input: AssetRepairTypeInput) {
    createAssetRepairType(input: $input) {
      id
    }
  }
`;

// Asset maintenance
export const createAssetMaintenance = gql`
  mutation createAssetMaintenance($input: AssetMaintenanceInput!) {
    createAssetMaintenance(input: $input) {
      id
    }
  }
`;

export const updateAssetMaintenance = gql`
  mutation updateAssetMaintenance(
    $id: ID!
    $input: AssetMaintenanceInput!
    $assetRepaired: Boolean
  ) {
    updateAssetMaintenance(
      id: $id
      input: $input
      assetRepaired: $assetRepaired
    ) {
      id
    }
  }
`;

export const reportAssetDamage = gql`
  mutation reportAssetDamage(
    $systemId: ID!
    $assetId: ID!
    $assetType: AssetType!
    $damages: [AssetMaintenanceDamageInput]!
  ) {
    reportAssetDamage(
      systemId: $systemId
      assetId: $assetId
      assetType: $assetType
      damages: $damages
    ) {
      assetId
    }
  }
`;

// Value codes
export const createValueCode = gql`
  mutation createValueCode($input: ValueCodeInput!) {
    createValueCode(input: $input) {
      id
    }
  }
`;

export const createCampaign = gql`
  mutation createCampaign($input: CampaignInput!) {
    createCampaign(input: $input) {
      id
      code
      state
      redeemedAt
      createdAt
      validUntil
    }
  }
`;

// Controllers
export const createController = gql`
  mutation createController($input: ControllerInput) {
    createController(input: $input) {
      id
      systemId
    }
  }
`;

export const updateController = gql`
  mutation updateController($id: ID!, $input: ControllerInput) {
    updateController(id: $id, input: $input) {
      id
      systemId
    }
  }
`;

export const deleteController = gql`
  mutation deleteController($id: ID!) {
    deleteController(id: $id) {
      id
    }
  }
`;

export const unpairControllerFromVehicle = gql`
  mutation unpairControllerFromVehicle($controllerId: ID!) {
    unpairControllerFromVehicle(controllerId: $controllerId) {
      id
    }
  }
`;

// Vehicles
export const createVehicle = gql`
  mutation createVehicle($input: VehicleInput) {
    createVehicle(input: $input) {
      id
      systemId
    }
  }
`;

export const updateVehicle = gql`
  mutation updateVehicle($id: ID!, $input: VehicleInput) {
    updateVehicle(id: $id, input: $input) {
      id
      systemId
    }
  }
`;

export const deleteVehicle = gql`
  mutation deleteVehicle($id: ID!) {
    deleteVehicle(id: $id) {
      id
    }
  }
`;

// Controller command
export const sendControllerMessage = gql`
  mutation sendControllerMessage(
    $controllerId: ID!
    $message: String!
    $payload: String!
  ) {
    sendControllerMessage(
      controllerId: $controllerId
      message: $message
      payload: $payload
    ) {
      response
    }
  }
`;

export const pingController = gql`
  mutation pingController($controllerId: ID!) {
    pingController(controllerId: $controllerId) {
      response
    }
  }
`;

// Trip
export const pairElectronWithPhysicalLock = gql`
  mutation pairElectronWithPhysicalLock(
    $physicalLockId: String!
    $imei: String!
    $overwrite: Boolean
  ) {
    pairElectronWithPhysicalLock(
      physicalLockId: $physicalLockId
      imei: $imei
      overwrite: $overwrite
    ) {
      id
    }
  }
`;

// User
export const CREATE_USER_MUTATION = gql`
  mutation createUser($input: UserInput) {
    createUser(input: $input) {
      id
      active
      superpowers
      systemId
      name
      gender
      birthYear
      phoneNumber
      email
      postalAddress
      postalCode
      countryName
      active
      rfid
    }
  }
`;
export const deleteUser = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;
export const updateUser = gql`
  mutation updateUser($id: ID!, $input: UserInput) {
    updateUser(id: $id, input: $input) {
      id
      name
    }
  }
`;
export const resetPhoneVerificationRateLimit = gql`
  mutation resetPhoneVerificationRateLimit(
    $systemId: ID!
    $phoneNumber: String!
  ) {
    resetPhoneVerificationRateLimit(
      systemId: $systemId
      phoneNumber: $phoneNumber
    )
  }
`;

// Incident
// export const updateIncident = gql`
//   mutation updateIncident($id: ID!, $input: IncidentInput) {
//     updateIncident(id: $id, input: $input) {
//       id
//     }
//   }
// `;

export const pairControllerWithVehicleFrameNumber = gql`
  mutation pairControllerWithVehicleFrameNumber(
    $physicalLockId: String!
    $frameNumber: String!
  ) {
    pairControllerWithVehicleFrameNumber(
      physicalLockId: $physicalLockId
      frameNumber: $frameNumber
    ) {
      id
    }
  }
`;

export const updateControllerFirmware = gql`
  mutation updateControllerFirmware(
    $id: ID!
    $filename: String
    $systemId: ID!
    $serialNumber: String!
  ) {
    updateControllerFirmware(id: $id, filename: $filename) {
      id
    }
    updateController(
      id: $id
      input: {
        systemId: $systemId
        serialNumber: $serialNumber
        desiredFirmwareVersion: null
      }
    ) {
      id
    }
  }
`;

export const createRfidAuth = gql`
  mutation createRfidAuth($input: RfidAuthInput) {
    createRfidAuth(input: $input) {
      id
    }
  }
`;

export const updateRfidAuth = gql`
  mutation updateRfidAuth($id: ID!, $input: RfidAuthInput) {
    updateRfidAuth(id: $id, input: $input) {
      id
    }
  }
`;

export const deleteRfidAuth = gql`
  mutation deleteRfidAuth($id: ID!) {
    deleteRfidAuth(id: $id) {
      id
    }
  }
`;

export const dockGroupCheckIn = gql`
  mutation dockGroupCheckIn($dockGroupId: ID!) {
    dockGroupCheckIn(dockGroupId: $dockGroupId) {
      id
    }
  }
`;

export const vehicleRFIDSearchCheckout = gql`
  mutation vehicleRFIDSearchCheckout($id: ID!, $input: VehicleInput) {
    updateVehicle(id: $id, input: $input) {
      id
      systemId
    }
    ownVehicle(id: $id) {
      id
    }
  }
`;

export const ownController = gql`
  mutation ownController(
    $systemId: ID!
    $physicalLockId: String!
    $imei: String!
  ) {
    ownController(
      systemId: $systemId
      physicalLockId: $physicalLockId
      imei: $imei
    ) {
      id
      physicalLockId
      systemId
      imei
      vehicleId
    }
  }
`;
